import barfi from '../images/products/sweets/barfi.png'
import besanbarfi from '../images/products/sweets/besanbarfi.png'
import chocolatebarfi from '../images/products/sweets/chocolatebarfi.png'
import gajar from '../images/products/sweets/gajar.png'
import gajarbarfi from '../images/products/sweets/gajarbarfi.png'
import mangobarfi from '../images/products/sweets/mangobarfi.png'
import mangococonutbarfi from '../images/products/sweets/mangococonutbarfi.png'
import pistachiobarfi from '../images/products/sweets/pistachiobarfi.png'
import pistachiostrawberrybarfi from '../images/products/sweets/pistachiostrawberrybarfi.png'
import gulabjamunsyrup from '../images/products/sweets/gulabjamunsyrup.png'
import gulabjamun from '../images/products/sweets/gulabjamun.png'
import gulabjamuncoconut from '../images/products/sweets/gulabjamuncoconut.png'
import kalajamun from '../images/products/sweets/kalajamun.png'
import almondjamun from '../images/products/sweets/almondjamun.png'
import dilbahar from '../images/products/sweets/dilbahar.png'
import punjabsweet from '../images/products/sweets/punjabsweet.png'
import petha from '../images/products/sweets/petha.png'
import rasgulla from '../images/products/sweets/rasgulla.png'
import pinkrasgulla from '../images/products/sweets/rasgullapink.png'
import chumchum from '../images/products/sweets/chumchum.png'
import pinkchumchum from '../images/products/sweets/chumchumpink.png'
import coconutrasgulla from '../images/products/sweets/coconutrasgulla.png'
import rasmalai from '../images/products/sweets/rasmalai.png'
import kalakand from '../images/image-coming-soon.png'
import milkcake from '../images/products/sweets/milkcake.png'
import besanladdoo from '../images/products/sweets/besanladdoo.png'
import motichoorladdoo from '../images/products/sweets/motichoorladdoo.png'
import pinni from '../images/products/sweets/pinni.png'
import alsipinni from '../images/products/sweets/alsipinni.png'
import jalebi from '../images/products/sweets/jalebi.png'
import soanpapri from '../images/products/sweets/soanpapri.png'
import khoya from '../images/products/sweets/khoya.png'
import kulfi from '../images/products/sweets/kulfi.png'

const sweets = [
  {
    name:  {
      en: 'Barfi',
      fr: 'Barfi'
    },
    image: barfi,
  },
  {
    name:  {
      en: 'Besan Barfi',
      fr: 'Besan Barfi'
    },
    image: besanbarfi,
  },
  {
    name:  {
      en: 'Chocolate Barfi',
      fr: 'Barfi Chocolat'
    },
    image: chocolatebarfi,
  },
  {
    name:  {
      en: 'Gajar',
      fr: 'Gajar'
    },
    image: gajar,
  },
  {
    name:  {
      en: 'Gajar Barfi',
      fr: 'Gajar Barfi'
    },
    image: gajarbarfi,
  },
  {
    name:  {
      en: 'Mango Barfi',
      fr: 'Barfi Mangue'
    },
    image: mangobarfi,
  },
  {
    name:  {
      en: 'Mango Coconut Barfi',
      fr: 'Barfi Mangue et Noix de Coco'
    },
    image: mangococonutbarfi,
  },
  {
    name:  {
      en: 'Pistachio Barfi',
      fr: 'Barfi Pistache'
    },
    image: pistachiobarfi,
  },
  {
    name:  {
      en: 'Pistachio Strawberry Barfi',
      fr: 'Barfi Pistache et Fraise'
    },
    image: pistachiostrawberrybarfi,
  },
  {
    name:  {
      en: 'Gulab Jamun (with Syrup)',
      fr: 'Gulab Jamun (avec Sirop)'
    },
    image: gulabjamunsyrup,
  },
  {
    name:  {
      en: 'Gulab Jamun',
      fr: 'Gulab Jamun'
    },
    image: gulabjamun,
  },
  {
    name:  {
      en: 'Coconut Gulab Jamun',
      fr: 'Gulab Jamun Noix de Coco'
    },
    image: gulabjamuncoconut,
  },
  {
    name:  {
      en: 'Kala Jamun',
      fr: 'Kala Jamun'
    },
    image: kalajamun,
  },
  {
    name:  {
      en: 'Almond Jamun',
      fr: 'Jamun Amande'
    },
    image: almondjamun,
  },
  {
    name:  {
      en: 'Dil Bahar',
      fr: 'Dil Bahar'
    },
    image: dilbahar,
  },
  {
    name:  {
      en: 'Punjab Sweet',
      fr: 'Punjab Sweet'
    },
    image: punjabsweet,
  },
  {
    name:  {
      en: 'Petha',
      fr: 'Petha'
    },
    image: petha,
  },
  {
    name:  {
      en: 'Rasgulla',
      fr: 'Rasgulla'
    },
    image: rasgulla,
  },
  {
    name:  {
      en: 'Pink Rasgulla',
      fr: 'Rasgulla Rose'
    },
    image: pinkrasgulla,
  },
  {
    name:  {
      en: 'Coconut Rasgulla',
      fr: 'Rasgulla Noix de Coco'
    },
    image: coconutrasgulla,
  },
  {
    name:  {
      en: 'Chum Chum',
      fr: 'Chum Chum'
    },
    image: chumchum,
  },
  {
    name:  {
      en: 'Pink Chum Chum',
      fr: 'Chum Chum Rose'
    },
    image: pinkchumchum,
  },
  {
    name:  {
      en: 'Ras Malai',
      fr: 'Ras Malai'
    },
    image: rasmalai,
  },
  {
    name:  {
      en: 'Kalakand',
      fr: 'Kalakand'
    },
    image: kalakand,
  },
  {
    name:  {
      en: 'Milk Cake',
      fr: 'Gâteau au Lait'
    },
    image: milkcake,
  },
  {
    name:  {
      en: 'Besan Laddoo',
      fr: 'Besan Laddoo'
    },
    image: besanladdoo,
  },
  {
    name:  {
      en: 'Motichoor Laddoo',
      fr: 'Motichoor Laddoo'
    },
    image: motichoorladdoo,
  },
  {
    name:  {
      en: 'Pinni',
      fr: 'Pinni'
    },
    image: pinni,
  },
  {
    name:  {
      en: 'Alsi Pinni',
      fr: 'Alsi Pinni'
    },
    image: alsipinni,
  },
  {
    name:  {
      en: 'Jalebi',
      fr: 'Jalebi'
    },
    image: jalebi,
  },
  {
    name:  {
      en: 'Soan Papri',
      fr: 'Soan Papri'
    },
    image: soanpapri,
  },
  {
    name:  {
      en: 'Khoya',
      fr: 'Khoya'
    },
    image: khoya,
  },
  {
    name:  {
      en: 'Kulfi',
      fr: 'Kulfi'
    },
    image: kulfi,
  },
]

export default sweets;
