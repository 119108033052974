import React from "react"
import { Link } from "react-scroll";

import Layout from "../components/layout"
import SEO from "../components/seo"
import sweets from '../data/sweets'
import snacks from '../data/snacks'
import drinks from '../data/drinks'
import MenuItem from '../components/menuItem';
import { injectIntl } from "gatsby-plugin-react-intl"
import { StaticImage } from "gatsby-plugin-image"

const SmoothMenuLink = ({ title }) => {
  const lowerCaseTitle = title.toLowerCase();
  return <Link to={lowerCaseTitle} offset={-120} duration={200} activeClass="active" spy={true} hashSpy={true} smooth={true} className="menu-nav__link">{title}</Link>
}

const Menu = ({ intl }) => (
  <Layout>
    <SEO title="Menu"/>
    <div className="hero-image">
      <p className="hero-image__text">{intl.formatMessage({ id: "nav.menu" })}</p>
      <StaticImage className="hero-image__image hero-image__image--dark" src="../images/shop/counter2.jpg" alt={intl.formatMessage({ id: "home.hero3.image" })}/>
    </div>
    <div className="menu">
      <div className="menu-nav">
        <ul className="menu-nav__ul">
          <li className="menu-nav__li menu-nav__title">{intl.formatMessage({ id: "menu.categories" })}</li>
          <li className="menu-nav__li"><SmoothMenuLink title={intl.formatMessage({ id: "menu.sweets" })}/></li>
          <li className="menu-nav__li"><SmoothMenuLink title={intl.formatMessage({ id: "menu.snacks" })}/></li>
          <li className="menu-nav__li"><SmoothMenuLink title={intl.formatMessage({ id: "menu.drinks" })}/></li>
        </ul>
      </div>
      <div className="menu-categories">
        <div className="menu__category menu__category--first" id={intl.formatMessage({ id: "menu.sweets" }).toLowerCase()}>
          <h2 className="menu-title">
            <span className="big-title">{intl.formatMessage({ id: "menu.sweets" })}</span>
          </h2>
          <div className="menu-items">
            {sweets.map(sweet => <MenuItem key={sweet.name[intl.locale]} name={sweet.name[intl.locale]} image={sweet.image}/>)}
          </div>
        </div>
        <div className="menu__category" id={intl.formatMessage({ id: "menu.snacks" }).toLowerCase()}>
          <h2 className="menu-title">
            <span className="big-title">{intl.formatMessage({ id: "menu.snacks" })}</span>
          </h2>
          <div className="menu-items">
            {snacks.map(snack => <MenuItem key={snack.name[intl.locale]} name={snack.name[intl.locale]} image={snack.image}/>)}
          </div>
        </div>
        <div className="menu__category menu__category--last" id={intl.formatMessage({ id: "menu.drinks" }).toLowerCase()}>
        <h2 className="menu-title">
          <span className="big-title">{intl.formatMessage({ id: "menu.drinks" })}</span>
        </h2>
        <div className="menu-items">
          {drinks.map(drink => <MenuItem key={drink.name[intl.locale]} name={drink.name[intl.locale]} image={drink.image}/>)}
        </div>
      </div>
      </div>
    </div>
  </Layout>
)

export default injectIntl(Menu)
