
import samosa from '../images/products/snacks/samosa.png'
import namakpara from '../images/products/snacks/namakpara.png'
import alootiki from '../images/products/snacks/alootiki.png'
import pakora from '../images/products/snacks/pakora.png'
import vegetableroll from '../images/products/snacks/vegetableroll.png'
import samosachaat from '../images/image-coming-soon.png'
import panipuri from '../images/image-coming-soon.png'
import pheni from '../images/products/snacks/pheni.png'

const snacks = [
  {
    name:  {
      en: 'Samosa',
      fr: 'Samosa'
    },
    image: samosa,
  },
  {
    name:  {
      en: 'Namak Para',
      fr: 'Namak Para'
    },
    image: namakpara,
  },
  {
    name:  {
      en: 'Aloo tiki',
      fr: 'Aloo tiki'
    },
    image: alootiki,
  },
  {
    name:  {
      en: 'Pakora',
      fr: 'Pakora'
    },
    image: pakora,
  },
  {
    name:  {
      en: 'Vegetable Roll',
      fr: 'Rouleaux aux légumes'
    },
    image: vegetableroll,
  },
  {
    name:  {
      en: 'Samosa Chaat',
      fr: 'Samosa Chaat'
    },
    image: samosachaat,
  },
  {
    name:  {
      en: 'Pani Puri/Gol Gappay',
      fr: 'Pani Puri/Gol Gappay'
    },
    image: panipuri,
  },
  {
    name:  {
      en: 'Pheni',
      fr: 'Pheni'
    },
    image: pheni,
  },
]

export default snacks;
