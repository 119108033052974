
import chai from '../images/image-coming-soon.png'
import juice from '../images/products/drinks/juice.png'
import softdrink from '../images/products/drinks/softdrink.png'
import water from '../images/products/drinks/water.png'

const drinks = [
  {
    name:  {
      en: 'Chai',
      fr: 'Chai'
    },
    image: chai,
  },
  {
    name:  {
      en: 'Juice',
      fr: 'Jus'
    },
    image: juice,
  },
  {
    name:  {
      en: 'Soft Drink',
      fr: 'Boisson Gazeuse'
    },
    image: softdrink,
  },
  {
    name:  {
      en: 'Water',
      fr: 'Eau'
    },
    image: water,
  },
]

export default drinks;
