import React from "react"
import LazyLoad from 'react-lazyload';

const MenuItem = ({ name, image, cost }) => (
  <div className="menu-card-wrapper" key={name}>
    <div className="menu-item menu-card">
      <LazyLoad height={160} once>
        <img src={image} className="menu-item__image" alt={name}/>
      </LazyLoad>
      <div className="menu-item__details">
        <h4 className="menu-item__name">{name}</h4>
      </div>
    </div>
  </div>
)

export default MenuItem
